<template>
  <div class="in-orders p-3 bg-white   box-shaddow20 overflow-auto">
    <h4 class="text-center ">لیست سفارشات</h4>
    <LineGrow open-width="90%" />
    <div :class="{ skeleton: !orders && status }" class="pb-3 flex-wrap">
      <template v-if="!mediaQueries.mobileSize">
        <b-skeleton v-if="!orders && status" type="button"></b-skeleton>
        <button
            v-else
            @click="showOrders(1, 'all')"
            class="statusBtn text-color-666 px-4 mr-3 border bg-none   fontsize14
            "
            :class="{ 'order-tracking-active': orderTrackingTab === 1 }"
        >
          همه
        </button>
        <b-skeleton v-if="!orders && status " type="button"></b-skeleton>
        <button
            v-else @click="showOrders(2, 'New')"
            class=" statusBtn text-color-666 px-4 mr-3 border bg-none   fontsize14"
            :class="{ 'order-tracking-active': orderTrackingTab === 2 }">
          جدید
          <span
              v-if="statistics != null && statistics.new != 0"
              class="statistics rounded-circle bg-color-themeRed text-white p-1 fontsize12 weight-bold">
              {{ statistics.new }}</span>
        </button>
        <b-skeleton v-if="!orders && status" type="button"></b-skeleton>
        <button v-else @click="showOrders(3, 'DELIVERED')"
                class=" statusBtn text-color-666 px-4 mr-3 border bg-none   fontsize14"
                :class="{ 'order-tracking-active': orderTrackingTab === 3 }"
        >
          تکمیل شده
          <span
              v-if="statistics != null && statistics.delivered != 0"
              class=" statistics rounded-circle bg-color-themeRed text-white p-1 fontsize12 weight-bold">
              {{ statistics.delivered }}</span>
        </button>
        <b-skeleton v-if="!orders && status" type="button"></b-skeleton>
        <button
            v-else
            @click="showOrders(4, 'IN_PROGRESS')"
            class="statusBtn text-color-666 px-4 mr-3 border bg-none   fontsize14"
            :class="{ 'order-tracking-active': orderTrackingTab === 4 }">
          دردست اقدام
          <span
              v-if="statistics != null && statistics.in_progress != 0"
              class=" statistics rounded-circle bg-color-themeRed text-white p-1 fontsize12 weight-bold">
              {{ statistics.in_progress }}</span>
        </button>
        <b-skeleton v-if="!orders && status" type="button"></b-skeleton>
        <button
            v-else
            @click="showOrders(5, 'RESERVED')"
            class=" statusBtn text-color-666 px-4 mr-3 border bg-none   fontsize14"
            :class="{ 'order-tracking-active': orderTrackingTab === 5 }">
          رزرو شده
          <span
              v-if="statistics != null && statistics.reserved != 0"
              class=" statistics rounded-circle bg-color-themeRed text-white p-1 fontsize12 weight-bold">
              {{ statistics.reserved }}</span>
        </button>
        <b-skeleton v-if="!orders && status" type="button"></b-skeleton>
        <button
            v-else
            @click="showOrders(6, 'WAIT_FOR_PAYMENT')"
            class=" statusBtn text-color-666 px-4 mr-3 border bg-none   fontsize14"
            :class="{ 'order-tracking-active': orderTrackingTab === 6 }">
          در انتظار پرداخت
          <span
              v-if="statistics != null && statistics.wait_for_payment != 0"
              class=" statistics rounded-circle bg-color-themeRed text-white p-1 fontsize12 weight-bold">
              {{ statistics.wait_for_payment }}</span>
        </button>
        <b-skeleton v-if="!orders && status" type="button"></b-skeleton>
        <button
            v-else
            @click="showOrders(7, 'CANCELED')"
            class=" statusBtn text-color-666 px-4 mr-3 border bg-none   fontsize14"
            :class="{ 'order-tracking-active': orderTrackingTab === 7 }">
          لغو شده
          <span
              v-if="statistics != null && statistics.canceled != 0"
              class="statistics rounded-circle bg-color-themeRed text-white p-1 fontsize12 weight-bold">
              {{ statistics.canceled }}</span>
        </button>
      </template>
      <template v-else>
        <treeselect :clearable="false" :options="orderOptions" v-model="selectedOrder" />
      </template>
    </div>
    <section
        id="orderSection" v-if="tab === 3" >
      <template v-if="status">
        <hr />
        <div class="skeleton">
          <b-skeleton v-if="status" type="button"></b-skeleton>

          <b-skeleton v-if="status" type="button"></b-skeleton>

          <b-skeleton v-if="status" type="button"></b-skeleton>

          <b-skeleton v-if="status" type="button"></b-skeleton>

          <b-skeleton v-if="status" type="button"></b-skeleton>

          <b-skeleton v-if="status" type="button"></b-skeleton>
        </div>
        <hr />
        <b-skeleton></b-skeleton>
        <hr />
        <b-skeleton></b-skeleton>
        <hr />
        <b-skeleton></b-skeleton>
        <hr />
        <b-skeleton></b-skeleton>
      </template>
      <template v-else>
        <div id="tabelBox">
          <!-- همه سفارشات -->
          <table v-if="orderTrackingTab === 1" class="table table-responsive" style="display: table;">
            <template v-if="orders.data.length != 0">
              <thead>
              <tr class="text-color-444 fontsize14">
                <th scope="col">شناسه</th>
                <th scope="col">هزینه ارسال</th>
                <th scope="col">تخفیف</th>
                <th scope="col">وضعیت</th>
                <th scope="col">قیمت</th>
                <th scope="col">تاریخ سفارش</th>
                <th scope="col">جزئیات</th>
              </tr>
              </thead>
              <tbody v-if="orders != null">
              <tr v-for="(order, index) in orders.data" class="trHover" :key="order.id">
                <td>{{ order.id }}</td>
                <td>
                  <template v-if="order.shipping_amount == null">رایگان</template>
                  <template v-else>{{ order.shipping_amount | price}}</template>
                <td>
                  <template v-if="order.discount_amount == 0">بدون تخفیف</template>
                  <template v-else>{{ order.discount_amount | price }}</template>
                </td>
                <td>
                  <OrderStatus :status="order.status"/>
                </td>
                <td>
                  <template v-if="findPriceOrder(order.invoices) == null">رایگان</template>
                  <template v-else>{{ findPriceOrder(order.invoices) | price}}</template>
                </td>
                <td style="word-spacing: 6px">{{ moment(order.created_at).format('HH:mm YYYY/MM/DD') }}</td>
                <td>
                  <router-link :to="{ name: 'order', params: { id: order.id } }">
                    <button class="showDetailBtn py-2 text-color-666 px-4 mr-3 border bg-none   fontsize14">
                      جزئیات <span class="go-to-icon"><ZNextSvg /></span>
                    </button>
                  </router-link>
                </td>
              </tr>
              </tbody>
            </template>
            <div class="notContent" v-else>
              <h3 class="fontsize-heavy">اطلاعاتی برای نمایش وجود ندارد</h3>
            </div>
          </table>
          <table v-if="orderTrackingTab != 1 && orders != null" class="table table-responsive" style="display: table">
            <template v-if="orders.data.length != 0">
              <thead>
              <tr class="text-color-444 fontsize14">
                <th scope="col">شناسه</th>
                <th scope="col">مبلغ</th>
                <th scope="col">تخفیف</th>
                <th scope="col">وضعیت</th>
                <th scope="col">تاریخ سفارش</th>
                <th scope="col">جزئیات</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(order, index) in orders.data" class="trHover" :key="order.id">
                <th scope="row">
                  {{
                    order.id
                  }}
                </th>
                <td>
                  <template v-if="findPriceOrder(order.invoices) == null">رایگان</template>
                  <template v-else>{{ findPriceOrder(order.invoices) | price }}</template>
                </td>
                <td>
                  {{ order.discount_amount == 0 ? "بدون تخفیف" : order.discount_amount }}
                </td>
                <td>
                  <OrderStatus :status="order.status"/>
                </td>
                <td style="word-spacing: 6px" scope="row">
                  {{ moment(order.created_at).format('HH:mm YYYY/MM/DD') }}
                </td>
                <td>
                  <router-link :to="{ name: 'order', params: { id: order.id } }">
                    <button
                        class="showDetailBtn py-2 text-color-666 px-4 mr-3 border bg-none   fontsize14">
                      جزئیات
                    </button>
                  </router-link>
                </td>
              </tr>
              </tbody>
            </template>
            <div class="notContent" v-else>
              <h4>اطلاعاتی برای نمایش وجود ندارد</h4>
            </div>
          </table>
        </div>

        <section id="mainPaginationBlog"
            v-if="orders && (orders.last_page > 1)" class="orders-list-pagination text-center mb-4 mt-0 p-2 bg-color-fa">
          <section id="listPagination" class="list-pagination orders-list-pagination text-center py-2">
            <pagination :limit="1" :data="orders" @pagination-change-page="changePage"
                :disabled="status" class="justify-content-center">
              <span slot="prev-nav">
                <ZPrevSvg />
              </span>
              <span slot="next-nav">
                <ZNextSvg />
              </span>
            </pagination>
          </section>
        </section>
      </template>
    </section>
  </div>
</template>
<script>
import {
  findPayments,
  findPriceOrder,
  getInvoicesStatus,
  getSumPrice
} from "@/libraries/functions";
import { Treeselect } from "@riophae/vue-treeselect";
import paginationMixin from "@/libraries/mixins/paginationMixin";
import LineGrow from "@/parts/Front/components/shared/LineGrow";
import OrderStatus from "@/parts/Front/components/panel/OrderStatus";
import ZPrevSvg from "@/parts/Front/components/shared/ZPrevSvg";
import ZNextSvg from "@/parts/Front/components/shared/ZNextSvg";
import pagination from "shetabit-laravel-vue-pagination"

export default {
  inject:['mediaQueries'],
  mixins: [paginationMixin],
  props: { tab: { Number } },
  data() {
    return {
      orderTrackingTab: 1,
      selectedOrder: "all",
      orderOptions: [
        {
          id: "all",
          label: "همه",
        },

        {
          id: "New",
          label: "جدید",
        },
        {
          id: "DELIVERED",
          label: "تکمیل شده",
        },
        {
          id: "IN_PROGRESS",
          label: "در دست اقدام",
        },
        {
          id: "RESERVED",
          label: "رزور شده",
        },
        {
          id: "WAIT_FOR_PAYMENT",
          label: "در انتظار پرداخت",
        },
        {
          id: "CANCELED",
          label: "لغو شده",
        },
      ],
    };
  },
  computed: {
    statistics() {
      return this.$store.getters["front/getOrderStatistics"];
    },
    // دریافت استاتوس وضعیت گرفتن سفارشات از سرور
    status() {
      return this.$store.getters["front/getOrdersInPanelStatus"];
    },
    // دریافت سفارشات از سرور
    orders() {
      return this.$store.getters["front/getOrdersInPanel"];
    },
  },
  created() {
    // اگر لیست سفارشات خالی بود از سرور گرفته شود
    if (this.orders == null) {
      let pack = {};
      if (this.$route.query.page) {
        pack.page = this.$route.query.page;
      } else {
        pack.page = 1;
      }
      if (this.$route.query.status != undefined) {
        pack.status = this.$route.query.status;
      } else {
        pack.status = "all";
      }
      this.$store.dispatch("front/getOrderInPanelFromServer", pack);
    }
  },
  watch: {
    selectedOrder(newVal) {
      if (newVal == "all") {
        this.orderTrackingTab = 1;
      }
      this.$router.replace({ path: this.$route.path, query: { ...this.$route.query, status: newVal } })
    },
    // در صورت تغییر کوئری صقحه با چجینیشن درخواست جدید داده شود
    "$route.query.status": {
      handler: function (val) {
        const data = {
          status: val != undefined ? val : "all",
        };
          data.page=this.$route.query.page != undefined ? this.$route.query.pagee : "1";
          this.$store.dispatch("front/getOrderInPanelFromServer", data);
        },
    },
     "$route.query.page": {
      handler: function (val) {
        const data = {
          page: val != undefined ? val : "1",
        };

          data.status=this.$route.query.status != undefined ? this.$route.query.status : "all";

          this.$store.dispatch("front/getOrderInPanelFromServer", data);
      },
    },
  },
  methods: {
    findPayments,
    findPriceOrder,
    getInvoicesStatus,
    getSumPrice,
    changePage(page) {
      this.$nextTick(() => {
        document.querySelector('.in-user-panel').scrollIntoView({behavior: "smooth"});
      });
      if (this.disabled) {
        return;
      }
      this.$router.replace({ path: this.$route.path, query: { ...this.$route.query, page: page } })
    },

    // با کلیک بروی دکمه ها خروجی سفارشات آن دسته نمایش داده میشود
    showOrders(orderTrackingTab, status) {
      this.orderTrackingTab = orderTrackingTab;
      this.$router.replace({path: this.$route.path, query:{...this.$route.query, status: status}});
    },
  },
  mounted() {
    // بررسی کوئری برای نمایش دکمه مورد نظر در اولین برخورد
    let status = this.$route.query.status;
    if (this.$route.query.status != undefined) {
      if (status.toLowerCase() == "all") {
        this.orderTrackingTab = 1;
        this.selectedOrder="all"
      } else {
        switch (status.toLowerCase()) {
          case "new":
            this.orderTrackingTab = 2;
            this.selectedOrder="New"
            break;

          case "delivered":
            this.selectedOrder="DELIVERED"
            this.orderTrackingTab = 3;
            break;

          case "in_progress":
            this.selectedOrder="IN_PROGRESS"
            this.orderTrackingTab = 4;
            break;
          case "reserved":
            this.selectedOrder="RESERVED"
            this.orderTrackingTab = 5;
            break;

          case "wait_for_payment":
            this.selectedOrder="WAIT_FOR_PAYMENT"
            this.orderTrackingTab = 6;
            break;

          case "canceled":
            this.selectedOrder="CANCELED"
            this.orderTrackingTab = 7;
            break;
        }
      }
    }
  },
  components: {
    ZNextSvg,
    ZPrevSvg,
    LineGrow,
    OrderStatus,
    Treeselect,
    pagination
  },
};
</script>
<style scoped>
/* افزودن استایل به کدهای اضافه شده به قالب */
.skeleton {
  display: flex;
  justify-content: space-around;
}
@media screen and (max-width: 570px){
  .skeleton {
    gap: 7px;
  }
}
.trHover:hover .showDetailBtn {
  background-color: #e8e8e8 !important;
}
.go-to-icon {
  position: absolute;
  left: 6px;
  top: 50%;
  transform: translate(20px,-56%);
  opacity: 0;
  transition: 250ms all;
}
.showDetailBtn:hover .go-to-icon {
  opacity: 1;
  transform: translate(0px,-56%);
}

.showDetailBtn {
  position: relative;
  background-color: rgba(239, 239, 239, 0.9) !important;
  transition: 200ms all;
}
.showDetailBtn:hover {
  box-shadow: 0 0 10px -5px white;
}
.trHover {
  transition: all 0.5s;
  box-shadow: inset 0 0 19px -21px;
}
.trHover:hover {
  box-shadow: inset 0 0 19px -16px;
  /*background: #fcfcfc;*/
  background: #ffffff;
}
.notContent {
  margin-top: 4%;
  text-align: center;
}
.statusBtn {
  height: 37px;
  margin-top: 5px;
  transition: all 0.5s
}
.statusBtn:hover{
  background-color: #121314 !important;
  color: white !important;
}
#statusBtns {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  position: relative;
}
#tabelBox,
#orderSection {
  width: 100%;
  overflow: auto;
}
.statistics{
  position: relative;
  background-color: var(--color-theme3);
  right: 24px;
  min-width: 26px;
  height: 100%;
  line-height: 31px;
  display: inline-block;
  border-radius: 0!important;
  font-size: 14px;
}
@media screen and (max-width: 570px){
  .in-orders >>> .b-skeleton-button {
    width: 60px;
  }
}
@media screen and (max-width: 420px){
  .in-orders >>> .b-skeleton-button {
    width: 40px;
  }
}

.in-orders >>> .pagination {
  margin-bottom: 0;
}

.orders-list-pagination {
  border-radius: 10px;
}

.order-tracking-active.border {
  background-color: var(--color-theme) !important;
  color: white !important;
  border: unset !important;
}
</style>
